import axios from 'axios';
import tools from '@/tools/cookie';
import store from '@/store';

const instance = axios.create();

instance.interceptors.request.use(config => {
  const token = tools.cookie
    .get(store.getters.authenticationRole === 'Student' ? 'studentToken' : 'adminToken');
  // config.baseURL = 'http://localhost:3000';
  config.baseURL = 'https://aimues-api.nw.r.appspot.com';
  config.headers.authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  res => res,
  err => err
);
export default () => instance;
